import { useEffect } from "react";

function ImageLoader({ imageUrls, onComplete, placeholder = "이미지를 캐시에 저장 중..." }) {
  useEffect(() => {
    const cacheImages = async () => {
      const promises = imageUrls.map((url) => {
        return new Promise((resolve) => {
          const img = new Image();
          img.src = url;
          img.onload = resolve; // 캐싱 성공
          img.onerror = resolve; // 에러 발생 시도
        });
      });

      await Promise.all(promises); // 모든 이미지 캐싱 완료 대기
      if (onComplete) onComplete(); // 캐싱 완료 후 콜백 실행
    };

    cacheImages();
  }, [imageUrls, onComplete]);

  return;
}

export default ImageLoader;