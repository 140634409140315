import React from 'react'
import './index.css'
import { useState, useEffect } from 'react'
import * as io from 'socket.io-client';
import socketConfig from '../../Socket/config.js'
import Modal from 'react-modal';
import moment from 'moment';

//const URL = "http://ec2-13-231-89-219.ap-northeast-1.compute.amazonaws.com:8080/";
const URL = "https://sc.poker-hill.com/";
const cashTexasSocket = io(URL + socketConfig.CashTexas,{
  transports: ['websocket','polling']
});
const cashOmahaSocket = io(URL + socketConfig.CashOmaha,{
  transports: ['websocket','polling']
});
const cashRegularOmahaSocket = io(URL + socketConfig.CashRegularOmaha,{
  transports: ['websocket','polling']
});
const CashRegularTexasSocket = io(URL,{
  transports: ['websocket','polling']
});
const playerData = JSON.parse(localStorage.getItem('user'));

const lang = require('../../Config/lang.js');

const InfoTable = (props) => {
  const [tableInfo, setTableInfo] = useState([])
  const [joinPlayerArr, setJoinPlayerArr] = useState([0, 1, 2, 3, 4, 5, 6, 7, 8])
  const [joinedPlayer, setJoinedPlayer] = useState([])
  const [roomDetails, setRoomDetails] = useState(props.roomDetails);
  const [interval, setIntervalId] = useState('');
  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);
  const [registerMessage, setRegisterMessage] = useState(false);
  const [playerRegister, setPlayerRegister] = useState(false);
  const [isMobile, setIsMobile] = useState(props.mobileView);
  const [isMobileModel, setIsMobileModel] = useState(false);

  useEffect(() => {
    let data = props.roomDetails;
    if (props.mobileView && data.type) {
      //console.log(data.type);
      setIsMobileModel(true);
    }
    clearInt();

    setRoomDetails(data);
    //--- 토너먼트 접속시 register <-> unregister 변경
    //setPlayerRegister(false);
    if (data.type == 'tournament') {
      /*const socket = io(URL,{
        transports: ['websocket','polling']
      });
      //console.log('1111111111111111111111');
      socket.emit('TournamentInfo', data, (res) => {
        if (res.result) {
          setTableInfo(res.result);
        }
      });

      socket.emit('TournamentPlayers', data, (res) => {
        if (res.result) {
          res.result.map((ply, i) => {
            if (ply.id == playerData.playerId)
              setPlayerRegister(true);
          })
        }
      });*/
      
      
      CashRegularTexasSocket.emit('TournamentInfo', data, (res) => {
        if (res.result) {
          setTableInfo(res.result);
        }
      });

      CashRegularTexasSocket.emit('TournamentPlayers', data, (res) => {
        if (res.result) {
          let playerFound = false;
          res.result.map((ply, i) => {
            if (ply.id == playerData.playerId) {
              playerFound = true;
              setPlayerRegister(true);
            } 
            if(!playerFound) {
              setPlayerRegister(false);
            }
          })
        }
      });

    }
    if (data.type == 'sng') {
      const socket = io(URL,{
        transports: ['websocket','polling']
      });
      socket.emit('SngTournamentInfo', data, (res) => {
        if (res.result) {
          setTableInfo(res.result);
        }
      });

      socket.emit('SngTournamentPlayers', data, (res) => {
        if (res.result) {
          res.result.map((ply, i) => {
            if (ply.id == playerData.playerId)
              setPlayerRegister(true);
          })
        }
      });

    }
    if (data.type == 'cash_texas') {
      cashTexasSocket.emit('RoomInfo', data, (res) => {
        if (res.result) {
          setTableInfo(res.result);
          setJoinedPlayer(res.result.joinedPlayer);
          //console.log('###############################1');
          //console.log(res.result.joinedPlayer);
          //console.log('###############################1');
        }
      });

      const intervalId = setInterval(() => {
        cashTexasSocket.emit('RoomInfo', data, (res) => {
          if (res.result) {
            setTableInfo(res.result);
            setJoinedPlayer(res.result.joinedPlayer);
            //console.log('###############################2');
            //console.log(res.result.joinedPlayer);
            //console.log('###############################2');
          }
        });
      }, 5000);

      setIntervalId(intervalId);

      return () => clearInterval(intervalId)
    }
    if (data.type == 'cash_omaha') {
      cashOmahaSocket.emit('RoomInfo', data, (res) => {
        if (res.result) {
          setTableInfo(res.result);
          setJoinedPlayer(res.result.joinedPlayer);
        }
      });

      const intervalId = setInterval(() => {
        cashOmahaSocket.emit('RoomInfo', data, (res) => {
          if (res.result) {
            setTableInfo(res.result);
            setJoinedPlayer(res.result.joinedPlayer);
          }
        });
      }, 5000);
      setIntervalId(intervalId);
      return () => clearInterval(intervalId)
    }
  }, [props]);

  const clearInt = () => {
    clearInterval(interval);
  }
  const handleLinkClick = () => {
    const aspectRatio = 16 / 9; // 4:3 aspect ratio
    const width = 1080; // initial width
    const height = Math.round(width / aspectRatio); // calculate height based on aspect ratio
    const windowFeatures = `resizable=no,width=${width},height=${height}`;
    const newWindow = window.open("http://3.144.120.117/@/game", "_blank", windowFeatures);
    const resizeWindow = () => {
      const currentWidth = newWindow.outerWidth;
      const currentHeight = newWindow.outerHeight;
      const expectedHeight = Math.round(currentWidth / aspectRatio);
      if (currentHeight !== expectedHeight) {
        newWindow.resizeTo(currentWidth, expectedHeight);
      }
    };
    newWindow.addEventListener("resize", resizeWindow);
  };

  const registerOnGame = (typ, id, type, gameType) => {
    const socket = io(URL,{
      transports: ['websocket','polling']
    });
    if (gameType == 'tournament') {
      const data = {
        playerId: playerData?.playerId,
        tournamentId: id,
        pokerGameType: type,
        productName: 'PokerHills'
      }
      let socketname = 'RegisterTournament'
      if (typ == 1) {
        socketname = 'UnRegisterTournament'
      }
      socket.emit(socketname, data, (res) => {
        if (res.status == "success") {
          if(socketname=='RegisterTournament')
            setPlayerRegister(true)
          else if(socketname=='UnRegisterTournament')
            setPlayerRegister(false);
          setRegisterMessage(res.message);
        }
        if (res.status == "fail") {
          setRegisterMessage(res.message);
        }
        setIsRegisterModalOpen(true);
      });
      socket.emit('TournamentPlayers', roomDetails, (res) => {
        if (res.result) {
          setPlayerRegister(false);
          res.result.map((ply, i) => {
            if (ply.id == playerData.playerId)
              setPlayerRegister(true);
          })
        }
      });

      socket.emit('TournamentInfo', data, (res) => {
        if (res.result) {
          setTableInfo(res.result);
        }
      });
    }
    if (gameType == 'sng') {
      const data = {
        playerId: playerData?.playerId,
        roomId: id,
        pokerGameType: type,
        productName: 'PokerHills'
      }
      let socketname = 'RegisterSngTournament'
      if (typ == 1) {
        socketname = 'UnRegisterSngTournament'
      }
      socket.emit(socketname, data, (res) => {
        if (res.status == "success") {
          setRegisterMessage(res.message);
        }
        if (res.status == "fail") {
          setRegisterMessage(res.message);
        }
        setIsRegisterModalOpen(true);
      });
      socket.emit('SngTournamentPlayers', roomDetails, (res) => {
        if (res.result) {
          setPlayerRegister(false);
          res.result.map((ply, i) => {
            if (ply.id == playerData.playerId)
              setPlayerRegister(true);
          })
        }
      });

      socket.emit('SngTournamentInfo', data, (res) => {
        if (res.result) {
          setTableInfo(res.result);
        }
      });
    }

  }


  const closeModal = () => {
    setIsMobileModel(false);
    setIsRegisterModalOpen(false);
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#242234',
      width: '40%',
      height: '50%',
      border: '1px solid #8025F3'
    },
  };

  const customStylesMbl = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#242234',
      width: '70%',
      height: '30%',
      border: '1px solid #8025F3'
    },
  };
  return (
    <div>
      {isMobile ?
        <div >
          {roomDetails.type == 'tournament' ?
            <div>
              <div className='tournamentTableInfoHeader d-flex p-2'>
                <h4>{tableInfo.name}</h4>
                {playerRegister ?
                  <button className='btn  p-tb-register-btn' onClick={() => registerOnGame(1, tableInfo.id, tableInfo.pokerGameType, 'tournament')}>Unregister</button>
                  :
                  <button className='btn  p-tb-register-btn' onClick={() => registerOnGame(0, tableInfo.id, tableInfo.pokerGameType, 'tournament')}>Register</button>
                }
              </div>
              <div className='infotableFixHead'>
                <p className='dateTime'>Starts : {tableInfo.dateTime}</p>
                <table className='table p-lb-table table-borderless m-0'>
                  <tbody>
                    <tr>
                      <td width='60%'>Registration Status</td>
                      <td>{tableInfo.registrationStatus}</td>
                    </tr>
                    <tr>
                      <td width='60%'>Price Pool</td>
                      <td>{Number(tableInfo.prizePool).toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td width='60%'>{lang.ko.buyin}</td>
                      <td>{tableInfo.buyIn}</td>
                    </tr>
                    <tr>
                      <td width='60%'>{lang.ko.players}</td>
                      <td>{tableInfo.players}</td>
                    </tr>
                    <tr>
                      <td width='60%'>Min Players</td>
                      <td>{tableInfo.min_players}</td>
                    </tr>
                    <tr>
                      <td width='60%'>Max Players</td>
                      <td>{tableInfo.max_players}</td>
                    </tr>
                    <tr>
                      <td width='60%'>Game Type</td>
                      <td>{tableInfo.pokerGameType}</td>
                    </tr>
                    <tr>
                      <td width='60%'>{lang.ko.status}</td>
                      <td>{tableInfo.status}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            : roomDetails.type == 'sng' ?
              <div>
                <div className='tournamentTableInfoHeader d-flex p-2'>
                  <h4>{tableInfo.name}</h4>
                  {playerRegister ?
                    <button className='btn  p-tb-register-btn' onClick={() => registerOnGame(1, tableInfo.id, tableInfo.pokerGameType, 'sng')}>Unregister</button>
                    :
                    <button className='btn  p-tb-register-btn' onClick={() => registerOnGame(0, tableInfo.id, tableInfo.pokerGameType, 'sng')}>Register</button>
                  }
                </div>
                <div className='infotableFixHead'>
                  <table className='table p-lb-table table-borderless m-0'>
                    <tbody>
                      <tr>
                        <td width='60%'>Registration Status</td>
                        <td>{tableInfo.registrationStatus}</td>
                      </tr>
                      <tr>
                        <td width='60%'>Price Pool</td>
                        <td>{Number(tableInfo.prizePool).toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td width='60%'>{lang.ko.buyin}</td>
                        <td>{tableInfo.buyIn}</td>
                      </tr>
                      <tr>
                        <td width='60%'>{lang.ko.players}</td>
                        <td>{tableInfo.players}</td>
                      </tr>
                      <tr>
                        <td width='60%'>Min Players</td>
                        <td>{tableInfo.min_players}</td>
                      </tr>
                      <tr>
                        <td width='60%'>Max Players</td>
                        <td>{tableInfo.max_players}</td>
                      </tr>
                      <tr>
                        <td width='60%'>Game Type</td>
                        <td>{tableInfo.gameType}</td>
                      </tr>
                      <tr>
                        <td width='60%'>{lang.ko.game}</td>
                        <td style={{ textTransform: "capitalize" }}>{tableInfo.pokerGameType}</td>
                      </tr>
                      <tr>
                        <td width='60%'>{lang.ko.status}</td>
                        <td>{tableInfo.status}</td>
                      </tr>

                    </tbody>
                  </table>
                </div>
              </div>
              : roomDetails.type == 'cash_texas' ?
                <div>
                  <div className='tableInfoHeader'>
                    <h4>{tableInfo.name}</h4>
                    {/*<img src={'../Images/close.png'} onClick={() => props.hideRoomInfo()}></img>*/}
                  </div>
                  <div className='infotableFixHead'>
                    <table className='table p-lb-table table-borderless m-0'>
                      <thead>
                        <tr>
                          <th>{lang.ko.player}</th>
                          <th>Pot</th>
                        </tr>
                      </thead>
                      <tbody>
                        {joinPlayerArr.map((ply, index) => {
                          return joinedPlayer.length > index ?
                            <tr key={index}>
                              <td width='60%'>{joinedPlayer[index].playerName}</td>
                              <td width='60%'>{joinedPlayer[index].entryChips ? Number(joinedPlayer[index].entryChips).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }): joinedPlayer[index].chips.toFixed(2)}</td>
                            </tr>
                            :
                            <tr key={index}>
                              <td width='60%'>-</td>
                              <td width='60%'>-</td>
                            </tr>
                            ;
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                : roomDetails.type == 'cash_omaha' ?
                  <div>
                    <div className='tableInfoHeader'>
                      <h4>{tableInfo.name}</h4>
                      <img src={'../Images/close.png'} onClick={() => props.hideRoomInfo()}></img>
                    </div>
                    <div className='infotableFixHead'>
                      <table className='table p-lb-table table-borderless m-0'>
                        <thead>
                          <tr>
                            <th>{lang.ko.player}</th>
                            <th>Pot</th>
                          </tr>
                        </thead>
                        <tbody>
                          {joinPlayerArr.map((ply, index) => {
                            return joinedPlayer.length > index ?
                              <tr key={index}>
                                <td width='60%'>{joinedPlayer[index].playerName}</td>
                                <td width='60%'>{joinedPlayer[index].entryChips ? Number(joinedPlayer[index].entryChips).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }): joinedPlayer[index].chips.toFixed(2)}</td>
                              </tr>
                              :
                              <tr key={index}>
                                <td width='60%'>-</td>
                                <td width='60%'>-</td>
                              </tr>
                              ;
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  : <div>

                  </div>
          }
          <Modal
            isOpen={isRegisterModalOpen}
            onRequestClose={closeModal}
            style={customStylesMbl}
            contentLabel="Example Modal"
            ariaHideApp={false}
          >
            <div className='gameModel'>
              <div className='mobileView'>
                <div className='modalBody'>
                  <center>
                    <h2 className='color-1'>Information</h2>
                    <h4 className='color-1 mt-4'>{registerMessage}</h4>
                    <div className='avtarBtnSection'>
                      <button className='edtAvtarBtn' onClick={() => closeModal()}>Ok</button>
                    </div>
                  </center>
                </div>
              </div>
            </div>
          </Modal>
        </div >
        :
        <div >
          {roomDetails.type == 'tournament' ?
            <div>
              <div className="mt-2">
                <div className='tournamentTableInfoHeader d-flex p-2'>
                  <h4>{tableInfo.name}</h4>
                  {playerRegister ?
                    <button className='btn  p-tb-register-btn' onClick={() => registerOnGame(1, tableInfo.id, tableInfo.pokerGameType, 'tournament')}>Unregister</button>
                    :
                    <button className='btn  p-tb-register-btn' onClick={() => registerOnGame(0, tableInfo.id, tableInfo.pokerGameType, 'tournament')}>Register</button>
                  }
                </div>
                <div className='infotableFixHead'>
                  <p className='dateTime'>Starts : {tableInfo.dateTime}</p>
                  <table className='table p-lb-table table-borderless m-0'>
                    <tbody>
                      <tr>
                        <td width='70%'>Registration Status</td>
                        <td>{tableInfo.registrationStatus}</td>
                      </tr>
                      <tr>
                        <td width='70%'>Price Pool</td>
                        <td>{Number(tableInfo.prizePool).toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td width='70%'>{lang.ko.buyin}</td>
                        <td>{tableInfo.buyIn}</td>
                      </tr>
                      <tr>
                        <td width='70%'>{lang.ko.players}</td>
                        <td>{tableInfo.players}</td>
                      </tr>
                      <tr>
                        <td width='70%'>Min Players</td>
                        <td>{tableInfo.min_players}</td>
                      </tr>
                      <tr>
                        <td width='70%'>Max Players</td>
                        <td>{tableInfo.max_players}</td>
                      </tr>
                      <tr>
                        <td width='70%'>Game Type</td>
                        <td>{tableInfo.pokerGameType}</td>
                      </tr>
                      <tr>
                        <td width='70%'>{lang.ko.status}</td>
                        <td>{tableInfo.status}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            : roomDetails.type == 'sng' ?
              <div>
                <div className="mt-2">
                  <div className='tournamentTableInfoHeader d-flex p-2'>
                    <h4>{tableInfo.name}</h4>
                    {playerRegister ?
                      <button className='btn  p-tb-register-btn' onClick={() => registerOnGame(1, tableInfo.id, tableInfo.pokerGameType, 'sng')}>Unregister</button>
                      :
                      <button className='btn  p-tb-register-btn' onClick={() => registerOnGame(0, tableInfo.id, tableInfo.pokerGameType, 'sng')}>Register</button>
                    }
                  </div>
                  <div className='infotableFixHead'>
                    <table className='table p-lb-table table-borderless m-0'>
                      <tbody>
                        <tr>
                          <td width='70%'>Registration Status</td>
                          <td>{tableInfo.registrationStatus}</td>
                        </tr>
                        <tr>
                          <td width='70%'>Price Pool</td>
                          <td>{Number(tableInfo.prizePool).toFixed(2)}</td>
                        </tr>
                        <tr>
                          <td width='70%'>{lang.ko.buyin}</td>
                          <td>{tableInfo.buyIn}</td>
                        </tr>
                        <tr>
                          <td width='70%'>{lang.ko.players}</td>
                          <td>{tableInfo.players}</td>
                        </tr>
                        <tr>
                          <td width='70%'>Min Players</td>
                          <td>{tableInfo.min_players}</td>
                        </tr>
                        <tr>
                          <td width='70%'>Max Players</td>
                          <td>{tableInfo.max_players}</td>
                        </tr>
                        <tr>
                          <td width='70%'>Game Type</td>
                          <td>{tableInfo.gameType}</td>
                        </tr>
                        <tr>
                          <td width='70%'>{lang.ko.game}</td>
                          <td style={{ textTransform: "capitalize" }}>{tableInfo.pokerGameType}</td>
                        </tr>
                        <tr>
                          <td width='70%'>{lang.ko.status}</td>
                          <td>{tableInfo.status}</td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              : roomDetails.type == 'cash_texas' ?
                <div>
                  <div className="mt-3">
                    <div className='tableInfoHeader'>
                      <h4>{tableInfo.name}</h4>
                      {/*<img src={'../Images/close.png'} onClick={() => props.hideRoomInfo()}></img>*/}
                    </div>
                    <div className='infotableFixHead'>
                      <table className='table p-lb-table table-borderless m-0'>
                        <thead>
                          <tr>
                            <th>{lang.ko.player}</th>
                            <th>Pot</th>
                          </tr>
                        </thead>
                        <tbody>
                          {joinPlayerArr.map((ply, index) => {
                            return joinedPlayer.length > index ?
                              <tr key={index}>
                                <td width='70%'>{joinedPlayer[index].playerName.split('_')[0]}</td>
                                {/*<td width='70%'>{joinedPlayer[index].entryChips ? Number(joinedPlayer[index].entryChips).toFixed(2) : joinedPlayer[index].chips.toFixed(2)}</td>*/}
                                <td width='70%'>{joinedPlayer[index].chips ? Number(joinedPlayer[index].chips).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : joinedPlayer[index].chips.toFixed(2)}</td>
                              </tr>
                              :
                              <tr key={index}>
                                <td width='70%'>-</td>
                                <td width='70%'>-</td>
                              </tr>
                              ;
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                : roomDetails.type == 'cash_omaha' ?
                  <div>
                    <div className="mt-3">
                      <div className='tableInfoHeader'>
                        <h4>{tableInfo.name}</h4>
                        <img src={'../Images/close.png'} onClick={() => props.hideRoomInfo()}></img>
                      </div>
                      <div className='infotableFixHead'>
                        <table className='table p-lb-table table-borderless m-0'>
                          <thead>
                            <tr>
                              <th>{lang.ko.player}</th> 
                              <th>Pot</th>
                            </tr>
                          </thead>
                          <tbody>
                            {joinPlayerArr.map((ply, index) => {
                              return joinedPlayer.length > index ?
                                <tr key={index}>
                                  <td width='70%'>{joinedPlayer[index].playerName}</td>
                                  <td width='70%'>{joinedPlayer[index].entryChips ? Number(joinedPlayer[index].entryChips).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : joinedPlayer[index].chips.toFixed(2)}</td>
                                </tr>
                                :
                                <tr key={index}>
                                  <td width='70%'>-</td>
                                  <td width='70%'>-</td>
                                </tr>
                                ;
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  : <div>
                    <div className='mt-3'>
                      <div className='tableInfoHeader'>
                        <h4>{lang.ko.no_table_selected}</h4>
                      </div>
                      <div className="infotableFixHead">
                        <table className='table p-lb-table table-borderless m-0'>
                          <thead>
                            <tr>
                              <th>{lang.ko.player}</th>
                              <th>Pot</th>
                            </tr>
                          </thead>
                          <tbody>
                            {joinPlayerArr.map((ply, index) => {
                              return joinedPlayer.length > index ?
                                <tr key={index}>
                                  <td width='70%'>{joinedPlayer[index].playerName}</td>
                                  <td width='70%'>{joinedPlayer[index].chips ? Number(joinedPlayer[index].chips).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : joinedPlayer[index].chips.toFixed(2)}</td>
                                  
                                </tr>
                                :
                                <tr key={index}>
                                  <td width='70%'>-</td>
                                  <td width='70%'>-</td>
                                </tr>
                                ;
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
          }

          <Modal
            isOpen={isRegisterModalOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
            ariaHideApp={false}
          >
            <div className='gameModel'>
              <div className='modalBody'>
                <center>
                  <h2 className='color-1'>Information</h2>
                  <h4 className='color-1 mt-4'>{registerMessage}</h4>
                  <div className='avtarBtnSection'>
                    <button className='edtAvtarBtn' onClick={() => closeModal()}>Ok</button>
                  </div>
                </center>
              </div>
            </div>
          </Modal>
        </div >
      }
    </div>

  );
}

export default InfoTable

